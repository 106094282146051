import React, { useState, useEffect } from "react";
import { Container, TextField, Button, List, ListItem, ListItemText, IconButton, Typography, Box, Tooltip, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import api, { baseURL } from "../../conf/api";
import CircleIcon from "@mui/icons-material/Circle";
import CachedIcon from '@mui/icons-material/Cached';
import { toast } from "react-toastify";

const DropboxPaths = () => {
    const [paths, setPaths] = useState([]);
    const [newPath, setNewPath] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isDropBoxConnected, setDropboxStatus] = useState(undefined);

    useEffect(() => {
        fetchPaths();
    }, []);

    useEffect(() => {
        const CheckDropBoxSatatus = async () => {
            setLoading(true);
            try {
                const res = await api.get("/auth/dropbox/authenticated");

                if (res.status === 200) {
                    setDropboxStatus(true);
                }
            } catch (err) {
                setDropboxStatus(false);
                // GetDropboxURL();
            } finally {
                setLoading(false);
            }
        };
        CheckDropBoxSatatus();

        // Set up an interval to run the function every 2 minutes (120000 milliseconds)
        const intervalId = setInterval(CheckDropBoxSatatus, 120000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    const fetchPaths = async () => {
        try {
            const response = await api.get("/auth/dropbox/paths");
            setPaths(response.data?.folders);
        } catch (error) {
            console.error("Error fetching paths", error);
        }
    };

    const handleAddPath = async () => {
        if (!newPath.trim()) return;
        setLoading(true);
        try {
            const response = await api.post("/auth/dropbox/check-folder/", { path: newPath });
            if (response.data.exists) {
                const add_res = await api.post("/auth/dropbox/folder/", { path: newPath });
                if (add_res.status === 201) {
                    setPaths([...paths, newPath]);
                    setNewPath("");
                    setError("");
                }
                else {
                    toast.error(add_res.data.message)
                    setError(add_res.data.message);
                }
            } else {
                setError("Path does not exist in Dropbox");
            }
        } catch (error) {
            const resp = error.response;
            if ((resp?.status === 404 || resp?.status === 400) && resp?.data?.error) {
                toast.error(resp?.data?.error);
            }
            console.error("Error checking/adding path", resp);
            setError("Failed to verify or add path");
        }
        finally {
            setLoading(false);
        }
    };

    const handleRemovePath = async (path) => {
        try {
            setLoading(true);
            await api.delete(`/auth/dropbox/folder`, { data: { path } });
            setPaths(paths.filter((p) => p !== path));
        } catch (error) {
            const resp = error.response;
            if (resp?.data?.error) {
                toast.warning(resp.data.error);
            }
            console.error("Error removing path", resp?.data);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="space-between" gap={2}>
                <Box
                    display={isDropBoxConnected === undefined ? "none" : "flex"}
                    alignItems="center"
                    component={!isDropBoxConnected && "a"}
                    href={`${baseURL}/auth/dropbox/access_token/`}
                    target="_blank"
                >
                    <Tooltip
                        title={!isDropBoxConnected && "Click to authenticate dropbox"}
                    >
                        <IconButton size="large">
                            <CircleIcon
                                fontSize="large"
                                color={isDropBoxConnected ? "success" : "error"}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={!isDropBoxConnected && "Click to authenticate dropbox"}
                    >
                        <Typography variant="h5">
                            Storage is {isDropBoxConnected ? "connected" : "disconnected"}
                        </Typography>
                    </Tooltip>
                </Box>
                <IconButton onClick={() => { fetchPaths(); setNewPath(""); setError(""); }} sx={{ height: "fit-content" }}>
                    <CachedIcon />
                </IconButton>
            </Box>

            <Box>
                <Typography variant="h4" gutterBottom>Manage Dropbox Paths</Typography>
                <TextField
                    label="Dropbox Folder Path"
                    variant="outlined"
                    fullWidth
                    value={newPath}
                    onChange={(e) => setNewPath(e.target.value)}
                    error={error}
                    helperText={error}
                    sx={{ mb: 2 }}
                    disabled={loading}
                    InputProps={{ startAdornment: loading ? <CircularProgress size={25} /> : <></> }}
                />
                <Button variant="contained" color="primary" disabled={!isDropBoxConnected || loading} onClick={handleAddPath} sx={{ mb: 2 }}>
                    Add Path
                </Button>
                <List>
                    {paths.map((path, index) => (
                        <ListItem key={index} secondaryAction={
                            <IconButton edge="end" onClick={() => handleRemovePath(path)}>
                                <DeleteIcon />
                            </IconButton>
                        }>
                            <ListItemText primary={path} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Container>
    );
};

export default DropboxPaths;
