import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Popover,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import VideoCard from "../components/VideoCard";
import InfoCard from "../components/InfoCard";
import api from "../../../conf/api";
import ClockLoader from "react-spinners/ClockLoader";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import Dropzone from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { ValidUrl } from "../../../utils";
import { useNavigate } from "react-router-dom"
import ChatDialog from "../../../Components/Dialogs/ChatDialog";
import GPTICON from "../../../static/icons/chat-gpt.png"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';

const countArrayCharacters = (arr) => {
  if (!Array.isArray(arr)) return 0; // Ensure it's an array

  const str = arr.join(""); // Convert array to string
  let count = 0;

  for (let char of str) {
    count += char === " " ? 2 : 1;
  }

  return count;
};

const YoutubeFieldsForm = ({
  video,
  errors,
  removeError,
  handleSubmit,
  onChange,
}) => {

  const [loading, setLoading] = useState(false);
  const [selectedField, SelectField] = useState(null);
  const [tagsByComma, setTagsByComma] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const initialFields = { ...video, title: video.title, description: video.description, tags: video.tags, thumbnail: video.thumbnail, edit_operations: "" }
  const [fields, setFields] = useState(initialFields);
  const [tagsCount, setTagsCount] = useState(countArrayCharacters(fields.tags));
  const thumbnailExists = video.thumbnail;

  const handleThumbnailDrop = (acceptedFiles) => {
    const thumbnail = acceptedFiles[0];
    // Check if the thumbnail meets the resolution criteria (1280x720)
    const img = new Image();
    img.src = URL.createObjectURL(thumbnail);

    img.onload = function () {
      if (img.width <= 1280 && img.height <= 720) {
        handleChange("thumbnail", thumbnail);
      } else {
        toast.warning(
          "Please upload a thumbnail with a maximum resolution of 1280x720."
        );
      }
    };
  };

  const languages = [
    "English",
    "Spanish",
    "French",
    "German",
    "Chinese",
    "Japanese",
    "Russian",
    "Arabic",
    "Hindi",
    "Portuguese",
  ];
  const handleLanguageClick = (field_name) => (event) => {
    setAnchorEl(event.currentTarget);
    SelectField(field_name);
  };

  const handleLanguageSelect = (language) => () => {
    if (selectedField) {
      AskFromCHATGPT(selectedField, language);
      handleLanguageClose();
    }
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (field, value) => {
    let new_value = [];
    if (tagsByComma && field === "tags") {
      new_value = [...value];
      let append_value = [];
      if (value?.length > 0) {
        append_value = new_value.pop().split(",");
      }
      new_value = [...new_value, ...append_value];
      setTagsCount(countArrayCharacters(new_value));
    } else {
      new_value = value;
    }
    setFields(pre => {
      const new_fields = { ...pre, [field]: new_value };
      onChange(new_fields);
      return { ...pre, [field]: new_value }
    });
    removeError();
  };

  const ChatGPT = async (test, field_name) => {
    setLoading(true);
    setTagsByComma(false);
    const data = { input_text: test };
    await api
      .post("/youtube/generate-response", { ...data }, { timeout: 120000 })
      .then((res) => {
        if (field_name === "tags") {
          const _value = JSON.parse(res.data);
          handleChange(field_name, _value);
        } else {
          handleChange(field_name, res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const AskFromCHATGPT = (field_name, language) => {
    const value = video[field_name];
    if (value) {
      let _value = "";
      if (field_name === "tags") {
        _value =
          `Convert the following tags to ${language} and return Array in the format:\n["item1", "item2", "item3"]\n` +
          value;
      } else {
        const capsity = field_name === "title" ? 100 : 5000;
        _value = `(${value})
        convert the text in to ${language} language and only ${capsity} character`;
      }
      ChatGPT(_value, field_name);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleLanguageClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          <ListSubheader>Select Language</ListSubheader>
          {languages.map((language) => (
            <ListItem
              sx={{ cursor: "pointer" }}
              focusRipple
              onClick={handleLanguageSelect(language)}
              key={language}
            >
              <ListItemText primary={language} />
            </ListItem>
          ))}
        </List>
      </Popover>

      {errors !== undefined &&
        Object.entries(errors)?.map(([_field, err]) => (
          <Box mb={3}>
            <Alert severity="error">{`${_field} : ${err}`}</Alert>
          </Box>
        ))}

      <Grid item container spacing={2} mt={0.5}>
        {/* Edit options */}
        <Box sx={{ px: 3, py: 1 }}>
          <Typography paragraph>Edit Video</Typography>
          <FormGroup row>
            <FormControlLabel
              control={<Switch size="small"
                checked={fields.edit_operations === "FLIP-X"}
                color="success"
                onChange={e => handleChange("edit_operations", e.target.checked ? "FLIP-X" : "")} />}
              label="Flip Horz" />
            <FormControlLabel
              control={<Switch size="small"
                checked={fields.edit_operations === "FLIP-Y"}
                defaultChecked color="success"
                onChange={e => handleChange("edit_operations", e.target.checked ? "FLIP-Y" : "")} />}
              label="Flip Vert" />
          </FormGroup>
        </Box>
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            size="small"
            label={`Title ${fields?.title?.length || 0} / 100`}
            variant="outlined"
            InputProps={{
              endAdornment:
                loading && selectedField === "title" ? (
                  <div sx={{ position: 'relative' }}>
                    <InputAdornment sx={{ position: 'absolute', top: 20, right: 0 }}>
                      <ClockLoader size={20} color="#36d7b7" />
                    </InputAdornment>
                  </div>
                ) : (
                  <div sx={{ position: 'relative' }}>
                    <InputAdornment sx={{ position: 'absolute', top: 20, right: 0 }}>
                      <IconButton onClick={handleLanguageClick("title")}>
                        <GTranslateIcon color="primary" fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  </div>
                ),
            }}
            multiline
            rows={4}
            inputProps={{ maxLength: 100 }}
            fullWidth
            required
            value={fields?.title || ""}
            onChange={(e) => handleChange("title", e.target.value)}
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <TextField
            size="small"
            label={`Description ${fields?.description?.length || 0} / 5000`}
            variant="outlined"
            InputProps={{
              endAdornment:
                loading && selectedField === "description" ? (
                  <div sx={{ position: 'relative' }}>
                    <InputAdornment sx={{ position: 'absolute', top: 20, right: 0 }}>
                      <ClockLoader size={20} color="#36d7b7" />
                    </InputAdornment>
                  </div>
                ) : (
                  <div sx={{ position: 'relative' }}>
                    <InputAdornment sx={{ position: 'absolute', top: 20, right: 0 }}>
                      <IconButton onClick={handleLanguageClick("description")}>
                        <GTranslateIcon color="primary" fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  </div>
                ),
            }}
            multiline
            rows={5}
            inputProps={{ maxLength: 5000 }}
            fullWidth
            required
            value={fields?.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>

        {/* Tags */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            size="small"
            id="tags"
            options={[]}
            freeSolo
            value={fields?.tags || []}
            onChange={(_, newValue) => handleChange("tags", newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                label={`Tags (${tagsCount}/500)`}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment:
                    loading && selectedField === "tags" ? (
                      <div sx={{ position: 'relative' }}>
                        <InputAdornment sx={{ position: 'absolute', top: 20 }}>
                          <ClockLoader size={20} color="#36d7b7" />
                        </InputAdornment>
                      </div>
                    ) : (

                      <div sx={{ position: 'relative' }}>
                        <InputAdornment sx={{ position: 'absolute', top: 20 }}>
                          <IconButton onClick={handleLanguageClick("tags")}>
                            <GTranslateIcon color="primary" fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                }}
              />
            )}
          />
        </Grid>
        {/* Thumbnail */}
        <Grid item xs={12}>
          <Typography paragraph>Thumbnail</Typography>
          {thumbnailExists ? (
            <Box
              sx={{
                width: "100%",
                minHeight: "100px",
                borderRadius: "4px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => {
                  handleChange("thumbnail", null);
                }}
                size="large"
                sx={{ position: "absolute", top: -10, left: 0 }}
              >
                <CancelIcon sx={{ color: "#fff" }} fontSize="large" />
              </IconButton>
              {thumbnailExists &&
                <img
                  src={
                    ValidUrl(thumbnailExists)
                      ? thumbnailExists
                      // : URL.createObjectURL(thumbnailExists)
                      : thumbnailExists
                  }
                  alt="Thumbnail"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              }
            </Box>
          ) : (
            <Dropzone onDrop={handleThumbnailDrop}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps({
                    className: "dropzone",
                  })}
                  sx={{
                    border: "2px dashed #aaa",
                    borderRadius: "4px",
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography variant="body2">
                    Drop thumbnail here or click to browse
                  </Typography>
                </Box>
              )}
            </Dropzone>
          )}
        </Grid>
      </Grid>
    </form >
  );
};

function UploadVideo({ selectedObjects, Reset, RemoveVideo }) {
  const navigate = useNavigate();

  const [gptDialog, showGPTDialog] = useState(false);
  const { videos, channels } = selectedObjects;
  const [translatedData, setTranslatedData] = useState({});
  const [selectedChannel, setSelectedChannel] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [translationFailed, setTranslationFailed] = useState([]);


  const ConvertToFormData = (data) => {
    const formData = new FormData();
    const _channals = [];
    for (const channel in data) {
      const videos = data[channel];
      const channelVideos = videos.map((video, index) => {
        const thumbnail_name = `${channel}-${index}-${video.video}`;
        if (video.thumbnail) {
          formData.append(thumbnail_name, video.thumbnail)
        }
        return ({
          [video.video]: {
            title: video.title,
            description: video.description,
            tags: video.tags,
            thumbnail: thumbnail_name,
            url: video.url,
            edit_operations: video.edit_operations
          }
        })
      });
      _channals.push({ [channel]: channelVideos })
    }
    formData.append("data", JSON.stringify(_channals));
    return formData;
  }
  const Validator = () => {
    const errorFormater = {};

    const fieldValidation = (_fields, _ch) => {
      let _error = false;

      _fields.forEach((f, index) => {
        let video_error = false;
        errorFormater[_ch] = errorFormater[_ch] || [];
        errorFormater[_ch][f.video] = errorFormater[_ch][f.video] || {};

        if (!f.title || f.title.length > 100) {
          errorFormater[_ch][f.video].title = "Title must be less than 100 characters and not empty.";
          video_error = true;
        }

        if (!f.description || f.description.length > 5000) {
          errorFormater[_ch][f.video].description = "Description must be less than 5000 characters and not empty.";
          video_error = true;
        }

        if (!f.tags || f.tags.length < 1) {
          errorFormater[_ch][f.video].tags = "At least one tag is required.";
          video_error = true;
        } else {
          const totalCharacters = countArrayCharacters(f.tags);
          if (totalCharacters > 500) {
            errorFormater[_ch][f.video].tags = "Tags can not be more then 500.";
            video_error = true;
          }
        }


        // if (f.thumbnail === null) {
        //   errorFormater[_ch][f.video].thumbnail = "Thumbnail is required.";
        //   video_error = true;
        // }
        if (video_error) {
          _error = true;
        }
        else {
          delete errorFormater[_ch][f.video]
        }
      });

      return _error;
    };

    for (const key in translatedData) {
      const _videos = translatedData[key];
      errorFormater[key] = [];
      fieldValidation(_videos, key);
      setErrors(errorFormater)
    }
    for (let key in errorFormater) {
      if (Array.isArray(errorFormater[key]) && Object.keys(errorFormater[key]).length === 0) {
        delete errorFormater[key];
      }
    }
    return Object.keys(errorFormater).length > 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (Validator()) {
      toast.error("Please correct the highlight form");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    // Append channel and videos data to the form data
    formData.append("channel", channels.channels);
    const payload = ConvertToFormData(translatedData);
    api
      .post("/youtube/upload", payload)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Video uploaded successfully. Now in queue for processing.");
          setTranslatedData({});
          setSelectedChannel("");
          Reset();
          navigate("/youtube/list?screen=others")
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something wents wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const generateFields = (_data, channel) => {
      const chVideos = [];
      for (const v of videos[channel.title]) {
        const _video_data = _data[channel.language.name?.toUpperCase()]?.find(key => v.unique_key in key);
        if (_video_data) {
          const { title, description, tags } = _video_data[v.unique_key];
          chVideos.push({
            video: v.unique_key,
            title: title,
            description: description + "\n " + channel.post_description,
            tags: tags,
            url: v.s3_url,
            s3_url: v.s3_url,
            thumbnail: v.portal_thumb,
          });
        }
      }
      return chVideos;
    };

    const translateVideo = async (channel, video) => {
      try {
        const res = await api.post(
          "/youtube/translate-fields/",
          {
            languages: channel.language?.name,
            fields: [{
              unique_key: video.unique_key,
              title: video.video_title,
              description: video.description,
              tags: video.tags?.split(","),
            }],
          },
          { timeout: 1000 * (60 * 10) }
        );
        if (res.status === 200) {
          const response = res.data;
          const _data = Object.fromEntries(
            Object.entries(response).map(([key, value]) => [key.toUpperCase(), value])
          );
          return generateFields(_data, channel);
        }
      } catch (error) {
        console.error(`Translation for video ${video.unique_key} failed:`, error);
        toast.error(`Translation for video ${video.unique_key} failed.`);
        setTranslationFailed(prev => ([...prev, channel.title]));
        return [{
          video: video.unique_key,
          title: video.video_title,
          description: video.description + "\n " + channel.post_description,
          tags: video.tags.split(","),
          url: video.s3_url,
          s3_url: video.s3_url,
          thumbnail: video.portal_thumb,
        }]
      }
    };

    const ChatGPT = async () => {
      setLoading(true);
      const data_objects = {}
      for (const channel of channels) {
        data_objects[channel.title] = []
        for (const video of videos[channel.title]) {
          const res = await translateVideo(channel, video)
          if (res) {
            const data = res[0];
            data_objects[channel.title].push(data)
          }
        }
      }

      setTranslatedData({ ...data_objects });
      setLoading(false);
      if (channels.length > 0) {
        setSelectedChannel(channels[0].title);
      }
    };

    ChatGPT();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const VideoForm = useCallback(({ video }) => {
    const videoErrors = errors[selectedChannel]?.[video.video] || {};
    return <YoutubeFieldsForm
      removeError={() => {
        try {
          const updated_errors = errors;
          delete updated_errors[selectedChannel][video.video]
          setErrors(updated_errors)
        }
        catch {

        }
      }
      }
      errors={videoErrors}
      video={video}
      onChange={handleFieldsChange(selectedChannel, video.video)}
      handleSubmit={handleSubmit}
    />
    // eslint-disable-next-line
  }, [selectedChannel, errors[selectedChannel]])

  const handleFieldsChange = (_channel_name, _key) => (_fields) => {
    setTranslatedData(prevState => ({
      ...prevState,
      [_channel_name]: prevState[_channel_name].map(item => {
        if (item.video === _key) {
          return _fields;
        }
        return item;
      })
    }));
  };
  const handleDelete = (key, index) => (_video) => {
    setTranslatedData(prevState => {
      const newState = { ...prevState };
      newState[key].splice(index, 1);
      return newState;
    });
    RemoveVideo({ unique_key: _video.video }, selectedChannel);
  }
  const handleGPTPromt = () => {
    showGPTDialog(true)
  }
  const handleChannelChange = (_ch) => () => {
    setSelectedChannel(_ch.title)
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <Box display="flex">
            <Typography variant="h6">Selected Channels</Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {channels.map(ch => (
            <Grid item key={ch.title} component={ButtonBase} onClick={handleChannelChange(ch)}>
              <InfoCard
                data={ch}
                disbaleSelection
                selected={selectedChannel === ch.title}
                translationFailed={translationFailed.includes(ch.title)}
                sx={{ boxShadow: ch.title in errors && Object.keys(errors[ch.title]).length > 0 && "5px 10px 10px #FF6666" }}
              >
                <Divider flexItem variant="inset" sx={{ mt: 1 }} />
                <Typography variant="subtitle2" mt={1}>
                  Channel ID: <strong>{ch.channels}</strong>
                </Typography>
                <Box display="flex" gap={2}>
                  <Typography variant="subtitle2">Category: <strong>{ch.category?.name}</strong></Typography>
                  <Typography variant="subtitle2">Languages: <strong>{ch.language.name}</strong></Typography>
                </Box>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Selected Videos</Typography>

            <Box component={ButtonBase} onClick={handleGPTPromt} mt={1.5} mb={3} color={"green"} fontSize={16} fontWeight={600}>
              <Box component="img" src={GPTICON} width={34} mr={.5} /> GPT
            </Box>
          </Box>
        </Grid>
        <Box>

        </Box>
        {loading ? (
          <Grid item xs={12}>
            <Box
              minHeight={230}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >

              <ClockLoader size={100} color="#36d7b7" />

            </Box>
          </Grid>
        ) : (
          selectedChannel === "" ?
            <Box
              minHeight={230}
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: 100 }} />
              <Typography color={"grey"}>Error while translating</Typography>
            </Box>
            :
            translatedData[selectedChannel]?.map((vid, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={vid.video}>
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 4,
                      height: "100%",
                      boxShadow:
                        selectedChannel in errors && vid.video in errors[selectedChannel] &&
                        "5px 10px 10px #FF6666;",
                    }}
                  >
                    <VideoCard video={vid} disbaleSelection onDelete={handleDelete(selectedChannel, index)} />
                    <VideoForm video={vid} />
                  </Paper>
                </Grid>)
            })
        )}

        <Grid item xs={12} textAlign="right">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="success"
            size="large"
            sx={{ borderRadius: 2, minWidth: 200, textTransform: "none" }}
            endIcon={<InputAdornment position="end"><SubscriptionsOutlinedIcon sx={{ color: "#fff" }} /></InputAdornment>}
            disabled={loading || !selectedChannel}
          >
            Submit Video
          </Button>
        </Grid>
      </Grid>
      <ChatDialog open={gptDialog} handleClose={() => { showGPTDialog(false) }} />
    </Box >
  );
}

export default UploadVideo;
