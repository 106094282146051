import * as actionTypes from "./actionTypes";
import api, { baseURL } from "../../conf/api.js";
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    access_token: data.access,
    refresh_token: data.refresh,
  };
};

export const authFail = (error) => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const authLogout = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const getRefreshToken = async (refreshToken) => {
  const refresh_token = refreshToken;
  const response = await axios
    .post(baseURL + "/auth/token/refresh/", {
      refresh: refresh_token,
    })
    .catch((res) => {
      console.log("Refresh Token", res);
    });
  return response?.data ? response?.data : null;
};

export const isAccessTokenExpired = (accessToken) => {
  try {
    const decodedToken = jwt_decode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
  } catch (err) {
    return true;
  }
};

export const authLogin = (email, password) => {
  return async (dispatch) => {
    dispatch(authStart());
    axios
      .post(baseURL + "/auth/token/", { email: email, password: password })
      .then((response) => {
        if (response.status === 200) {
          dispatch(authSuccess(response.data));
        }
      })
      .catch((error) => {
        try {
          if (error.response?.data?.detail) {
            toast.error(error.response.data?.detail);
            dispatch(authFail(error.response?.data?.detai));
          }
          else {
            toast.error("Something went wrong!");
            dispatch(authFail("Something went wrong"));
          }
        } catch {
          toast.error("Something went wrong!");
          dispatch(authFail(error.status));
        }
      });
  };
};

export const authSignup = (data, afterSuccess, onError = (error) => { }) => {
  return async (dispatch) => {
    await api
      .post("/auth/register/", data, { timeout: 10000 * 60 * 1 })
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data?.message);
          afterSuccess();
        }
      })
      .catch((error) => {
        onError(error.response);
        const errorObject = error.response?.data?.message;
        try {
          for (const field in errorObject) {
            if (errorObject?.hasOwnProperty(field)) {
              const errors = errorObject[field];
              const formattedFieldErrors = errors.map(
                (errorMessage) => `${field}:${errorMessage}`
              );
              formattedFieldErrors?.map((msg) => toast.error(msg));
            }
          }
        } catch {
          try {
            toast.error(JSON.stringify(errorObject));
          } catch {
            toast.error("Something went wrong");
          }
        }
      });
  };
};

export const authCheckState = () => {
  return async (dispatch) => {
    const accessToken = Cookies.get("access_token");
    const refreshToken = Cookies.get("refresh_token");
    try {
      if (
        !accessToken ||
        accessToken === "undefined" ||
        refreshToken === "undefined" ||
        !refreshToken
      ) {
        dispatch(authFail("Cookies is not authenticated"));
        if (
          window.location.pathname !== "/" &&
          window.location.pathname !== "/terms" &&
          window.location.pathname !== "/creator_signup" &&
          window.location.pathname !== "/partner_signup169"
        ) {
          window.location.replace("/");
        }
        return;
      }
      if (isAccessTokenExpired(accessToken)) {
        const response = await getRefreshToken(refreshToken);
        dispatch(authSuccess(response));
      } else {
        dispatch(authSuccess({ access: accessToken, refresh: refreshToken }));
      }
    } catch { }
  };
};
