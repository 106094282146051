import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import { Link, useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../conf/api";
import Scrollbars from "react-custom-scrollbars-2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserPopupBox from "./components/UserPopupBox.js";
import { connect } from "react-redux";
import { authSignup } from "../../store/actions/actions.js";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from '@mui/icons-material/Key';
import ConfirmDeleteUserBox from "./components/ConfirmDeleteUserBox.js";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PasswordResetBox from "./components/PasswordResetBox.js";
import UserChannelsPreview from "./components/UserChannelsPreview.js";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import TvIcon from '@mui/icons-material/Tv';
import { toast } from "react-toastify";

function StaffList(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryScreen = searchParams.get("screen");
  const [view, setView] = useState(queryScreen || "admin");
  const [params, setParams] = useState(null);
  const [data, setData] = useState([]);
  const [userBoxOpen, setUserBoxOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: parseInt(searchParams.get("page") || 1),
    pageSize: parseInt(searchParams.get("page_size") || 10)
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmBoxOpen, setConfirmBoxOpen] = useState(false);
  const [passResetOpen, setPassResetOpen] = useState(false);
  const [channelPreviewOpen, setChannelPreviewOpen] = useState(false);

  const handleUpdateUser = useCallback((id) => {
    setSelectedRow(id);
    setIsUpdate(true);
    setUserBoxOpen(true);
  }, []);

  const handleDeleteUser = useCallback((id) => {
    setSelectedRow(id);
    setConfirmBoxOpen(true);
  }, []);

  const handlePassReset = useCallback((user) => {
    setSelectedRow(user);
    setPassResetOpen(true);
  }, []);

  const handleChannelPreview = useCallback((user) => {
    setSelectedRow(user);
    setChannelPreviewOpen(true);
  }, []);

  const handlePaginationChange = useCallback((newModel) => {
    const newPage = newModel.page + 1; // Ensure it's 1-based

    setPaginationModel(prevState => {
      const updatedModel = { ...prevState, ...newModel, page: newPage };

      // Update search params for pagination
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("page", newPage);
      updatedParams.set("page_size", newModel.pageSize);
      setSearchParams(updatedParams);

      return updatedModel;
    });
    fetchData(params, { ...newModel, page: newPage });
  }, [params, searchParams, setSearchParams]);


  const fetchData = useCallback(async (fetchParams = params, pagination = paginationModel) => {
    setLoading(true);
    setData([]);
    setRowCount(0);

    try {
      const res = await api.get("/users/list", { params: { ...fetchParams, page: pagination.page, page_size: pagination.pageSize } });
      setData(res.data.results);
      setRowCount(res.data.count);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [params, paginationModel]);

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const pageSize = parseInt(searchParams.get("page_size") || 10);

    setPaginationModel({ page, pageSize });
  }, [searchParams]);

  // Build params based on view and pagination
  useEffect(() => {
    const newParams = {
      is_creator: view === "creator",
      is_promouser: view === "promo",
      is_admin: view === "admin",
      // page: paginationModel.page,
      // page_size: paginationModel.pageSize,
    };
    setParams(newParams);
  }, [view]);

  // Sync view state with query params
  useEffect(() => {
    if (queryScreen !== view) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("screen", view);
      setSearchParams(updatedParams);
    }
  }, [view, queryScreen, searchParams, setSearchParams]);


  const gridStyles = useMemo(() => ({
    "& .deleted-row": {
      backgroundColor: "rgba(255, 0, 0, 0.1)",
    },
  }), []);


  const columns = useMemo(() => [
    { field: "id", headerName: "ID", sortable: true, width: 90 },
    { field: "username", headerName: "User", sortable: true, width: 200 },
    { field: "email", headerName: "Email", sortable: true, minWidth: 200 },
    {
      field: "paypal_email",
      headerName: "Paypal Email",
      sortable: true,
      minWidth: 180,
    },
    { field: "country", headerName: "Country", sortable: true },
    {
      field: "promo_shares",
      headerName: "Promo Revenue (%)",
      type: "number",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "creator_revenew",
      headerName: "Creator Revenue (%)",
      sortable: true,
      minWidth: 160,
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Active",
      sortable: true,
      align: "center",
      width: 90,
      type: "boolean",
    },
    {
      field: "deleted",
      headerName: "Deleted",
      sortable: true,
      width: 90,
      align: "center",
      type: "boolean",
    },
    {
      field: "date_joined",
      headerName: "Created Date",
      sortable: true,
      width: 180,
      type: "dateTime",
      valueGetter: (params) => new Date(params.row?.date_joined),
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 220,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {params.row.is_promouser && (
            <Tooltip title="Channels view">
              <IconButton size="small" onClick={() => handleChannelPreview(params.row)}>
                <TvIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.contract && (
            <Tooltip title="Download Contract">
              <Box
                component="a"
                target="_blank"
                display="flex"
                alignItems="center"
                href={params.row.contract.replace("http:", "https:")}
              >
                <DescriptionOutlinedIcon fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}
          <Tooltip title="Edit User">
            <IconButton size="small" onClick={() => handleUpdateUser(params.row.id)}>
              <CreateIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete User">
            <IconButton size="small" onClick={() => handleDeleteUser(params.row.id)}>
              <DeleteIcon fontSize="small" sx={{ color: "error.dark" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Password">
            <IconButton size="small" onClick={() => handlePassReset(params.row)}>
              <KeyIcon fontSize="small" sx={{ color: "coral" }} />
            </IconButton>
          </Tooltip>
          {!params.row.is_verified && (
            <Tooltip title="Generate New Verification Key">
              <IconButton size="small" onClick={regenerateVerificationLink(params.row)}>
                <ForwardToInboxIcon fontSize="small" sx={{ color: "error.light" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ], [handleChannelPreview, handleUpdateUser, handleDeleteUser, handlePassReset]);

  const regenerateVerificationLink = useCallback((user) => async () => {
    try {
      const res = await api.post(
        "/auth/verification/regen/",
        { email: user.email },
        { timeout: 1000 * 60 * 5 }
      );
      if (res.status === 200) {
        toast.info(res.data);
        fetchData();
      }
    } catch (err) {
      toast.error("Something went wrong.");
    }
  }, [fetchData]);

  const UserDialog = useCallback(() => (
    <UserPopupBox
      selectedRow={selectedRow}
      is_update={isUpdate}
      open={userBoxOpen}
      setOpen={setUserBoxOpen}
      registerUser={props.authSignup}
      refreshFunc={fetchData}
      is_promouser={view === "promo"}
      is_staff={view === "admin"}
      is_creator={view === "creator"}
    />
  ), [selectedRow, isUpdate, userBoxOpen, props.authSignup, fetchData, view]);

  const DeleteUserDialog = useCallback(() => (
    <ConfirmDeleteUserBox
      selectedRow={selectedRow}
      open={confirmBoxOpen}
      setOpen={setConfirmBoxOpen}
      refreshFunc={fetchData}
    />
  ), [confirmBoxOpen, selectedRow, fetchData]);

  const PassResetDialog = useCallback(() => (
    selectedRow &&
    <PasswordResetBox
      selectedRow={selectedRow}
      open={passResetOpen}
      setOpen={setPassResetOpen}
    />
  ), [passResetOpen, selectedRow]);

  const UserChannelsPreviewBox = useCallback(() => (
    selectedRow &&
    <UserChannelsPreview
      selectedRow={selectedRow}
      open={channelPreviewOpen}
      setOpen={setChannelPreviewOpen}
    />
  ), [channelPreviewOpen, selectedRow]);

  const UsersView = ({ viewType, columns, excludedFields }) => {
    const filteredColumns = useMemo(
      () => columns.filter((c) => !excludedFields.includes(c.field)),
      [columns, excludedFields]
    );
    return (
      <Collapse style={{ overflow: "auto" }} in={view === viewType || (viewType === "admin" && !view)} unmountOnExit>
        <Box mt={2} component={Paper} borderRadius={4}>
          <Box
            border="1px solid"
            borderColor="grey.400"
            borderRadius="10px 10px 0 0"
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="grey" fontWeight={600}>
              All {viewType}
            </Typography>
            <Tooltip title="Add new user">
              <IconButton
                size="small"
                onClick={() => {
                  setIsUpdate(false);
                  setUserBoxOpen(true);
                }}
              >
                <AddCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          </Box>
          <Scrollbars style={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={filteredColumns}
              loading={loading}
              pageSizeOptions={[10, 20, 50]}
              paginationMode="server"
              rowHeight={38}
              rowCount={rowCount}
              paginationModel={{ ...paginationModel, page: paginationModel.page - 1 }}
              onPaginationModelChange={handlePaginationChange}
              sx={gridStyles}
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              getRowClassName={(params) => (params.row.deleted ? "deleted-row" : "")}
            />
          </Scrollbars>
        </Box>
      </Collapse >
    );
  };

  // Determine which fields to exclude based on view
  const excludedFields = useMemo(() => {
    if (view === "admin") return ["channels"];
    if (view === "creator") return ["channels", "promo_shares"];
    if (view === "promo") return ["creator_revenew"];
    return [];
  }, [view]);

  return (
    <Box>
      <Box
        mt={2}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        gap={1}
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ color: "grey.600" }}>
          Users List
        </Typography>
        <Box
          sx={{ bgcolor: "grey.300" }}
          p={{ xs: 1, md: 2 }}
          borderRadius={6.5}
        >
          <Breadcrumbs aria-label="breadcrumb" sx={{
            "& .MuiTypography-subtitle2": { fontSize: { xs: 10, md: "0.875rem" } }
          }}>
            <Box component={Link} color="grey" underline="hover" sx={{ display: "flex", alignItems: "center" }} to="/">
              <HomeIcon fontSize="inherit" sx={{ mr: 0.5 }} />
              <Typography variant="subtitle2">Admin Portal</Typography>
            </Box>
            <Box component={Link} color="grey" underline="hover" sx={{ display: "flex", alignItems: "center" }} to="/">
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <Typography variant="subtitle2">Staff Management</Typography>
            </Box>
            <Typography sx={{ display: "flex", alignItems: "center" }} color="primary.light">
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <Typography variant="subtitle2">List Staff</Typography>
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>

      <Box display="flex" justifyContent={{ xs: "space-between", md: "flex-start" }} gap={{ xs: 1, md: 2.5 }} mt={1}>
        <Button
          onClick={() => {
            // setPaginationModel(prev => ({ ...prev, page: 1 }));
            setView("admin");
          }}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            textTransform: "none",
            color: view === "admin" ? "white" : "grey",
            boxShadow: view === "admin" && "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
            fontSize: { xs: "0.75rem", md: "1rem" },
            padding: { xs: "4px 8px", md: "8px 16px" }
          }}
          variant={view === "admin" ? "contained" : "text"}
        >
          Users View
        </Button>
        <Button
          onClick={() => {
            // setPaginationModel(prev => ({ ...prev, page: 1 }));
            setView("creator");
          }}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            textTransform: "none",
            color: view === "creator" ? "white" : "grey",
            boxShadow: view === "creator" && "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
            fontSize: { xs: "0.75rem", md: "1rem" },
            padding: { xs: "4px 8px", md: "8px 16px" }
          }}
          variant={view === "creator" ? "contained" : "text"}
        >
          Creator View
        </Button>
        <Button
          onClick={() => {
            setPaginationModel(prev => ({ ...prev, page: 1 }));
            setView("promo");
          }}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            textTransform: "none",
            color: view === "promo" ? "white" : "grey",
            boxShadow: view === "promo" && "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
            fontSize: { xs: "0.75rem", md: "1rem" },
            padding: { xs: "4px 8px", md: "8px 16px" }
          }}
          variant={view === "promo" ? "contained" : "text"}
        >
          Promo View
        </Button>
      </Box>
      <UsersView viewType={view} columns={columns} excludedFields={excludedFields} />

      {/* Modals */}
      <UserDialog />
      <DeleteUserDialog />
      <PassResetDialog />
      <UserChannelsPreviewBox />
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  authSignup: (fields, afterSuccess) => dispatch(authSignup(fields, afterSuccess)),
});

export default connect(null, mapDispatchToProps)(StaffList);
