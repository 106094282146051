import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Container, Grid, IconButton, InputAdornment, InputBase, Pagination, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from 'react-router-dom';
import api from '../../../conf/api';
import VideoScreen from './VideoScreen';
import VideoPreviewDialogBox from '../components/VideoPreviewDialogBox';

function Library() {
    const theme = useTheme();
    const searchRef = useRef(null);
    const [previewVideo, setPreviewVideo] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const initialFilters = {
        collapesCategories: true,
        collapesFormate: false,
        collapesDateTime: true,
        collapseSortBy: true,
        collapseDisplay: true,
        created_at__lte: "",
        created_at__gte: "",
        updated_at__lte: "",
        updated_at__gte: "",
        categories: [],
        sortBy: ["created_at"],
        display: "DESC",
        duration: null,
    };

    const [filterFields, setFilterFields] = useState(initialFilters);
    const initialPagination = {
        page: parseInt(searchParams.get("page")),
        count: 0
    }
    const [pagination, setPagination] = useState(initialPagination)
    const initalData = {
        data: [],
        loading: false,
    };
    const [videos, setVideos] = useState(initalData);

    const handleSearch = (e) => {
        GetFilteredVideo()
    }
    const handlePreviewVideo = (vid) => {
        setSelectedRow(vid)
        setPreviewVideo(true);
    }

    const GetFilteredVideo = async (
        page = pagination.page,
        _categories = null,
        apply_category_filter = true,
    ) => {
        setVideos({ ...initalData, loading: true });
        const send_params = {
            ordering: encodeURI(filterFields.sortBy),
            orderType: filterFields.display,
            page: page,
            limit: matches ? 52 : 20,
        };
        if (searchRef.current != null) {
            send_params.search = searchRef.current.value;
        }
        if (apply_category_filter) {
            send_params.categories = _categories
                ? encodeURI(_categories)
                : encodeURI(filterFields.categories);
        }
        let dateTime = {
            created_at__gte: filterFields.created_at__gte,
            created_at__lte: filterFields.created_at__lte,
            updated_at__lte: filterFields.updated_at__lte,
            updated_at__gte: filterFields.updated_at__gte,
        };
        if (
            (filterFields.created_at__gte && filterFields.created_at__lte) ||
            (filterFields.updated_at__lte && filterFields.updated_at__gte)
        ) {
            send_params.dateTime = dateTime;
        }
        if (filterFields.duration !== null) {
            send_params.duration = filterFields.duration;
        }
        api
            .get(`videos/library`, {
                params: send_params,
            })
            .then((res) => {
                const response = res.data;
                const count = response.count;
                setVideos(pre => ({ ...pre, data: response.data }))
                setPagination(pre => ({ ...pre, count: count }))
            })
            .catch(() => {
                setVideos((pre) => ({
                    ...pre,
                    data: [],
                    loading: false,
                }));
            }).finally(res => {
                setVideos((pre) => ({
                    ...pre,
                    loading: false,
                }));
            });
    };


    useEffect(() => {
        GetFilteredVideo()
    }, [pagination.page])
    useEffect(() => {
        setSearchParams(pre => ({ ...pre, page: pagination.page }));
    }, [pagination.page])

    const PreviewDailogBox = useCallback(() => {
        return <VideoPreviewDialogBox open={previewVideo && selectedRow} setOpen={setPreviewVideo} video={selectedRow} />
    }, [previewVideo, selectedRow])


    const PagintionModel = useCallback(() => {
        return (<Pagination
            count={matches
                ? Math.ceil(pagination.count / 20)
                : Math.ceil(pagination.count / 52)}
            page={pagination.page}
            onChange={(e, _page) => {
                setPagination(pre => ({ ...pre, page: _page }))
            }}
            disabled={videos.loading}
            shape="rounded"
        />)
    }, [pagination.page, pagination.count, videos.loading])
    return (
        <Container maxWidth="xl">
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box display="flex" width="100%" justifyContent="space-between" mt={5}>
                    <Paper
                        component={InputBase}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSearch(e);
                            }
                        }}
                        inputRef={searchRef}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="Search Video"
                        sx={{ minWidth: { xs: 300, md: 600 }, height: 50, padding: 2, borderRadius: 8, mb: 2 }}
                    />
                </Box>
                <Stack spacing={2} sx={{ mt: { xs: 2, md: 4 } }}>
                    <PagintionModel />
                </Stack>
                <Grid container spacing={1} mt={1}>
                    {videos.loading ?
                        <Grid item xs={12}>
                            <Box sx={{ width: "100%", height: "50vh" }} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress color='success' size={100} />
                            </Box>
                        </Grid>
                        :
                        videos.data.map((video, index) => (
                            <Grid key={video.unique_key} item xs={6} sm={6} md={4} lg={3}>
                                <VideoScreen video={video} onClick={handlePreviewVideo} />
                            </Grid>
                        ))}
                </Grid>

                <Stack spacing={2} sx={{ mt: { xs: 2, md: 4 } }}>
                    <PagintionModel />
                </Stack>
            </Box>
            {/* Video Preview Dialog */}
            <PreviewDailogBox />

        </Container>
    )
}

export default Library
