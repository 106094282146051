import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api, { baseURL } from "../../conf/api";
import { DataGrid } from "@mui/x-data-grid";
import Scrollbars from "react-custom-scrollbars-2";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { toast } from "react-toastify";
import ReUploadDialogBox from "./components/ReUploadDialogBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VideoPreviewBox from "./components/VideoPreviewBox";
import CircleIcon from "@mui/icons-material/Circle";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ModelBox from "../../Components/Dialogs/ModelBox";
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';
import UserSelector from "../../Components/Selectors/UserSelector";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VideoStatusDialogBox from "./components/VideoStatusDialogBox.js";


function VideoPreview(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const formatDate = (dateValue) => {
    return dateValue ? new Date(dateValue).toLocaleString() : "00/00/0000, 00:00";
  };


  const initialFilterField = {
    from_date: searchParams.get("from_date") ? dayjs(searchParams.get("from_date")) : dayjs().subtract(7, "days"),
    to_date: searchParams.get("to_date") ? dayjs(searchParams.get("to_date")) : dayjs(),
    sort_by: searchParams.get("sort_by") || "-created_date",
    user: searchParams.get("user") || null,
  };

  const [filters, setFilters] = React.useState(initialFilterField);
  const [isDropBoxConnected, setDropboxStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  // const [rejectionDialog, showRejectionDialog] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, selectVideo] = useState({});
  const [uploadDialog, openUploadDialog] = useState(false);
  const [videoPreviewBox, openVideoPreviewBox] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [status, setStatus] = useState("Approval");
  const [dialogBox, showDialogBox] = useState(false);

  const initialPagination = {
    page: parseInt(searchParams.get("page")) || 0,
    rowPerPage: parseInt(searchParams.get("rowPerPage")) || 10,
    count: 0,
  };

  const [pagination, setPagination] = useState(initialPagination);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const downloadVideo = useCallback((url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.substring(url.lastIndexOf("/") + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const columns = useMemo(() => [
    props.user?.is_staff && {
      field: "action",
      align: "center",
      headerAlign: "center",
      headerName: "Approve / Reject",
      minWidth: 150,
      renderCell: (params) => (
        <Box display="flex" width="100%" gap={0.5}>
          {/* {!params.row.approved && (
            <Tooltip title="Click to approve">
              <IconButton
                disabled={!isDropBoxConnected}
                onClick={() => {
                  handlePartialUpdate(params.row.id, "approve");
                }}
              >
                <ThumbUpAltIcon
                  color={isDropBoxConnected ? "success" : "grey"}
                />
              </IconButton>
            </Tooltip>
          )} */}
          {params.row.status !== "Pending" &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" && (
              <Tooltip title="Click to reupload">
                <IconButton
                  onClick={() => {
                    selectVideo(params.row);
                    openUploadDialog(true);
                  }}
                >
                  <CloudUploadIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}

          {/* {!params.row?.rejected &&
            !params.row?.dropbox_link &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" && (
              <Tooltip title="Click to reject">
                <IconButton
                  onClick={() => {
                    showRejectionDialog(true);
                    selectVideo(params.row);
                  }}
                >
                  <ThumbDownAltIcon color="error" />
                </IconButton>
              </Tooltip>
            )} */}
          {params.row.status === "Failed" && (
            <Tooltip title="Restart process">
              <IconButton onClick={handleReStart(params.row.id)}>
                <CloudSyncIcon color="info" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.status !== "Pending" &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" &&
            !params.row.approved &&
            <Tooltip title="Delete Video">
              <IconButton onClick={handleDelete(params.row.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          }
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: isSmallScreen ? 100 : 120,
    },
    // { field: "id", headerName: "ID", minWidth: 120 },
    { field: "username", headerName: "User" },
    {
      field: "file",
      headerName: "Download",
      minWidth: 90,
      renderCell: (params) => (
        <Tooltip title="Download">
          <IconButton
            onClick={() => {
              if (params.row.dropbox_link) {
                downloadVideo(params.row.dropbox_link);
              } else {
                // downloadVideo(params.value);
                selectVideo(params.row);
                openVideoPreviewBox(true);
              }
            }}
          >
            <DownloadingIcon color={"primary"} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "assert_id",
      headerName: "Assets",
      minWidth: 120,
      renderCell: (params) =>
        params.value ? params.value : <i style={{ color: "grey" }}>none</i>,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: isSmallScreen ? 150 : 200,
    },
    {
      field: "created_date",
      type: "datetime",
      headerName: "Created",
      minWidth: isSmallScreen ? 100 : 200,
      valueGetter: (params) => formatDate(params.value)
    },
    {
      field: "duration",
      headerName: "Duration",
      align: "center",
      minWidth: isSmallScreen ? 70 : 90,
      valueGetter: (params) => {
        if (!params.value) {
          return "0 sec";
        }

        const seconds = params.value;

        if (seconds >= 3600) {
          // Convert to hours
          return `${(seconds / 3600).toFixed(1)} hr`;
        } else if (seconds >= 60) {
          // Convert to minutes
          return `${(seconds / 60).toFixed(1)} min`;
        } else {
          // Keep in seconds
          return `${seconds.toFixed(1)} sec`;
        }
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      minWidth: isSmallScreen ? 70 : 90,
      valueGetter: (params) => formatDate(params.value)
    },
    {
      field: "approval_date",
      type: "datetime",
      headerName: "Approval Date",
      minWidth: isSmallScreen ? 150 : 200,
      valueGetter: (params) => formatDate(params.value)
    },
    // {
    //   field: "rejected",
    //   type: "boolean",
    //   headerName: "Rejected",
    //   minWidth: isSmallScreen ? 70 : 90,
    // },
    {
      field: "rejection_note",
      headerName: "Rejection Reason",
      minWidth: isSmallScreen ? 150 : 200,
      renderCell: (params) =>
        params.value ? params.value : <i style={{ color: "grey" }}>none</i>,
    },
    {
      field: "rejected_date",
      type: "datetime",
      headerName: "Rejection Date",
      minWidth: isSmallScreen ? 150 : 200,
      valueGetter: (params) => formatDate(params.value)
    },
    {
      field: "dropbox_link",
      headerName: "DropBox",
      minWidth: isSmallScreen ? 300 : 600,
      renderCell: (params) =>
        params.value ? (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ) : (
          <Typography variant="caption">Not link</Typography>
        ),
    },
  ], [isSmallScreen, props.user?.is_staff, isDropBoxConnected]);

  const FetchData = useCallback(async (page = pagination.page, page_size = pagination.rowPerPage) => {
    setLoading(true);
    setVideos([]);
    try {
      // Format dates correctly
      const formattedFilters = {
        ...filters,
        user: filters.user && (filters.user instanceof Object) ? filters.user?.username : filters.user === "null" ? null : filters.user,
        from_date: filters.from_date ? dayjs(filters.from_date).format("YYYY-MM-DD") : undefined,
        to_date: filters.to_date ? dayjs(filters.to_date).format("YYYY-MM-DD") : undefined,
      };

      const res = await api.get("/videos/api", {
        params: { page: page + 1, page_size, ...formattedFilters }, timeout: 1000 * 60 * 5
      });

      if (res.status === 200) {
        setVideos(res.data.results);
        setPagination((prev) => ({ ...prev, page: page, count: res.data.count }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [filters, pagination.page, pagination.rowPerPage]);


  useEffect(() => {
    FetchData();
  }, [pagination.page, pagination.rowPerPage, filters]);

  useEffect(() => {
    let params = { ...filters, page: pagination.page, rowPerPage: pagination.rowPerPage };
    if (params["user"]?.username) {
      params["user"] = params["user"].username
    }
    setSearchParams(params);
  }, [pagination.page, pagination.rowPerPage, filters, setSearchParams]);

  // const handlePartialUpdate = async (videoId, dataToUpdate, note = "") => {
  //   // try {
  //   api
  //     .post(`/videos/approve/${videoId}/${dataToUpdate}/`, {
  //       note: note,
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success(res.data);
  //         setPagination(initialPagination);
  //         FetchData();
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response?.data);
  //       // fetchData();
  //     });
  //   // } catch (resp) {
  //   //   toast.error(resp.response.data);
  //   //   console.log("Error updating data:", resp);
  //   // }
  // };

  const handleReStart = (videoID) => async () => {
    api
      .get(`/videos/restartvideo/${videoID}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          FetchData();
        }
      })
      .catch((res) => {
        toast.error(res.response.data);
      });
  };
  const handleDelete = (videoID) => async () => {
    api
      .delete(`/videos/api/${videoID}`)
      .then((res) => {
        if (res.status === 204) {
          toast.info(videoID + " Deleted successfully.");
        }
      })
      .catch((res) => {
        toast.error(res.response?.data);
        console.log(res.response)
      }).finally(() => {
        FetchData();
      });

  };
  const handleDateFilter = (_fields) => (newValue) => {
    setFilters((pre) => ({ ...pre, [_fields]: newValue }));
  };

  const VideoDialog = useCallback(() => {
    return (
      <ReUploadDialogBox
        open={uploadDialog}
        setOpen={openUploadDialog}
        selectedVideo={selectedVideo}
        refrashFunc={FetchData}
      />
    );
    // eslint-disable-next-line
  }, [uploadDialog, selectedVideo]);

  const VideoPreviewDialogBox = useCallback(() => {
    return (
      <VideoPreviewBox
        downloadVideo={downloadVideo}
        video={selectedVideo}
        open={videoPreviewBox && selectedVideo.file}
        onClose={() => {
          openVideoPreviewBox(false);
        }}
      />
    );
  }, [videoPreviewBox, selectedVideo]);

  const VideoStatusDialog = useCallback(() => {
    return (
      <VideoStatusDialogBox
        open={dialogBox}
        status={status}
        onClose={() => {
          showDialogBox(false)
        }}
        videos={selectedVideos}
        refrashFunc={FetchData}
      />
    );
  }, [dialogBox, selectedVideos, setStatus]);

  useEffect(() => {
    const CheckDropBoxSatatus = async () => {
      try {
        const res = await api.get("/auth/dropbox/authenticated");

        if (res.status === 200) {
          setDropboxStatus(true);
        }
      } catch (err) {
        setDropboxStatus(false);
        // GetDropboxURL();
      }
    };

    // Run the function initially
    CheckDropBoxSatatus();

    // Set up an interval to run the function every 2 minutes (120000 milliseconds)
    const intervalId = setInterval(CheckDropBoxSatatus, 120000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // const RejectionNoteDialog = useCallback(() => {
  //   const onConfirm = () => {
  //     const note = document.getElementById("rejection_note").value;
  //     if (note && selectedVideo.id) {
  //       handlePartialUpdate(selectedVideo.id, "reject", note);
  //       document.getElementById("rejection_note").value = "";
  //       showRejectionDialog(false);
  //     } else {
  //       toast.warning("Write some note.");
  //     }
  //   };
  //   return (
  //     <ModelBox
  //       open={rejectionDialog}
  //       setOpen={showRejectionDialog}
  //       title={"Rejection Note ( " + selectedVideo.id + " )"}
  //     >
  //       <Box mt={1} textAlign="right">
  //         <TextField
  //           multiline
  //           minRows={4}
  //           id="rejection_note"
  //           fullWidth
  //           placeholder="Cause of rejection."
  //         />
  //         <Button
  //           sx={{ mt: 2, textTransform: "none" }}
  //           variant="contained"
  //           color="warning"
  //           startIcon={<ThumbDownAltIcon color="error" />}
  //           onClick={onConfirm}
  //         >
  //           Confirm reject
  //         </Button>
  //       </Box>
  //     </ModelBox>
  //   );
  //   // eslint-disable-next-line
  // }, [rejectionDialog, selectedVideo]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
      <Typography variant="h4" paragraph>
        Videos
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <UserSelector
            filters={{ is_creator: true, is_promouser: false }}
            value={filters.user}
            onChange={(e, _u) => { setPagination(pre => ({ ...pre, page: 0 })); setFilters(pre => ({ ...pre, user: _u })) }}
          />
        </Grid>
        <Grid item xs={11} sm={6} md={4} lg={3} xl={2}>
          <Select
            fullWidth
            value={filters.sort_by}
            onChange={e => setFilters(pre => ({ ...pre, sort_by: e.target.value }))}
            displayEmpty
            size="small"
          >
            <MenuItem value="created_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Created Date</Box></MenuItem>
            <MenuItem value="-created_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Created Date</Box></MenuItem>
            <Divider variant="middle" />
            <MenuItem value="uploaded_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Upload Date</Box></MenuItem>
            <MenuItem value="-uploaded_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Upload Date</Box></MenuItem>
            <Divider variant="middle" />
            <MenuItem value="approval_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Approval Date</Box></MenuItem>
            <MenuItem value="-approval_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Approval Date</Box></MenuItem>
            <Divider variant="middle" />
            <MenuItem value="rejected_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Rejected Date</Box></MenuItem>
            <MenuItem value="-rejected_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Rejected Date</Box></MenuItem>
          </Select>

        </Grid>
        <Grid item container spacing={1} sx={{
          "& .MuiInputBase-input": {
            height: 10
          }
        }}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={filters.from_date}
                onChange={handleDateFilter("from_date")}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To"
                value={filters.to_date}
                onChange={handleDateFilter("to_date")}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>

      {props.user?.is_staff && (
        <Box
          display={isDropBoxConnected === undefined ? "none" : "flex"}
          alignItems="center"
          component={!isDropBoxConnected && "a"}
          href={`${baseURL}/auth/dropbox/access_token/`}
          target="_blank"
        >
          <Tooltip
            title={!isDropBoxConnected && "Click to authenticate dropbox"}
          >
            <IconButton size="large">
              <CircleIcon
                fontSize="large"
                color={isDropBoxConnected ? "success" : "error"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={!isDropBoxConnected && "Click to authenticate dropbox"}
          >
            <Typography variant="h5">
              Storage is {isDropBoxConnected ? "connected" : "disconnected"}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={.5}>
        <Box sx={{ display: "flex" }} gap={2}>
          {selectedVideos.length > 0 && selectedVideos.every(v => !v.approved) &&
            <Tooltip title="Click to approve selected videos">
              <Button

                disabled={!isDropBoxConnected}
                startIcon={
                  <ThumbUpAltIcon />
                }
                color="success"
                variant="contained"
                onClick={() => {
                  setStatus("Approval");
                  showDialogBox(true)
                }}
              >
                {`Approve selected (${selectedVideos.length})`}
              </Button>
            </Tooltip>
          }
          {selectedVideos.length > 0 && selectedVideos.every(row => (!row?.rejected && !row?.dropbox_link && row.status !== "Running" && row.status !== "INQUEUE")) &&
            <Tooltip title="Click to reupload">
              <Button

                disabled={!isDropBoxConnected}
                startIcon={
                  <ThumbDownAltIcon />
                }
                color="error"
                variant="contained"
                onClick={() => {
                  setStatus("Rejection")
                  showDialogBox(true)
                }}
              >
                Reject selected
              </Button>
            </Tooltip>
          }
        </Box>

        <IconButton onClick={() => FetchData()} sx={{ height: "fit-content" }}>
          <CachedIcon />
        </IconButton>
      </Box>


      <Scrollbars style={{ height: pagination.rowPerPage > 40 ? 700 : 500 }}>
        <DataGrid
          columns={columns}
          rows={videos}
          loading={loading}
          checkboxSelection
          rowHeight={35}
          paginationMode="server"
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.rowPerPage,
          }}
          rowCount={pagination.count}
          pageSizeOptions={[2, 10, 50, 100]}
          onPaginationModelChange={(newPage) => {
            const { page, pageSize } = newPage;
            setPagination((prev) => ({
              ...prev,
              page: page,
              rowPerPage: pageSize,
            }));
          }}
          onRowSelectionModelChange={(selectedIds) => {
            setSelectedVideos(videos.filter(v => selectedIds.includes(v.id)));
          }}
          rowSelectionModel={selectedVideos.map(v => v.id)}
        />
      </Scrollbars>


      <VideoPreviewDialogBox />
      <VideoDialog />
      <VideoStatusDialog />
      {/* <RejectionNoteDialog /> */}
    </Box >
  );
}

const mapStatesToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStatesToProps, null)(VideoPreview);
