import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Scrollbars } from "react-custom-scrollbars-2";
import CircularSpinner from "../../../Components/Progress/CircularSpinner.js";
import { toast } from "react-toastify";
import api from "../../../conf/api.js";
import Cookies from "js-cookie";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import UserSelector from "../../../Components/Selectors/UserSelector.js";
import { connect } from "react-redux";

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [when, message]);
};
const videoFormats = [
  "mp4",
  "webm",
  "mkv",
  "mov",
  "flv",
  "wmv",
  "mpeg",
  "mpg",
  "ogv",
];

const VideoUploader = (props) => {
  const [inProcess, setProcess] = useState(false);
  const [videos, setVideos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploading, setUploading] = useState({ uploaded: false });
  const [user, setUser] = useState(null);

  const onDrop = async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const formate = file.path.split(".").splice(-1)[0];
      if (file.name.length > 50) {
        setErrors([
          {
            show: true,
            severity: "warning",
            message: `(${file.name.slice(0, 10)}...) title can not be more than 50 characters..`,
          },
        ]);
        continue;
      }
      if (!videoFormats.includes(formate.toLowerCase())) {
        setErrors([
          {
            show: true,
            severity: "warning",
            message: `${file.name} not spported.`,
          },
        ]);
      } else if (file.type.startsWith("video/")) {
        // Load the video to get its duration
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onload = () => {
          console.log(video);
        };
        video.onloadedmetadata = async () => {
          const duration = video.duration;
          // if (duration >= 20) {
          const newVideo = {
            loading: false,
            title: file.name.replace(/\.[^/.]+$/, ""),
            file: file,
            extension: file.name.split(".").pop(),
            duration: duration,
          };
          // if duration and title match the we consider it exist already
          if (
            videos.find(
              (f) =>
                f.title ===
                file.name.replace(/\.[^/.]+$/, "" && duration === f.duration)
            )
          ) {
            setErrors([
              {
                show: true,
                severity: "warning",
                message: `Video ${file.name} already exist with same duration ${duration}.`,
              },
            ]);
            return;
          }
          setVideos((prevVideos) => [...prevVideos, newVideo]);
          // } else {
          //   // Notify the user that the video is too short
          //   setErrors([
          //     {
          //       show: true,
          //       severity: "warning",
          //       message: `Video ${file.name} is too short. It should be at least 20 seconds.`,
          //     },
          //   ]);
          // }
          // Clean up the video element
          URL.revokeObjectURL(video.src);
        };
      } else {
        setErrors([
          {
            show: true,
            severity: "warning",
            message: `${file.name}-(${file.type}) not allowed `,
          },
        ]);
      }
    }
  };

  const removeVideo = (index, event) => {
    event.stopPropagation();
    const updatedVideos = [...videos];
    const removedVideo = updatedVideos[index];

    // Stop the ongoing progress simulation (if any)
    if (removedVideo.loading) {
      // Clear the progress simulation timeout
      removedVideo.loading = false;
      clearTimeout(removedVideo.progressTimeout);
    }

    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };
  const handleVideoTitle = (index) => (e) => {
    if (e.target.value.length <= 15) {
      let _videos = videos;
      _videos[index].title = e.target.value;
      setVideos(_videos);
    } else {
      document.getElementById(e.target.id).value = e.target.value.slice(0, 15);
    }
  };
  const uploadVideos = async () => {
    setUploading((prev) => ({ ...prev, uploaded: true }));
    setProcess(true);
    setErrors([]);
    const username = user?.username || null;

    let videoUploaded = new Set();
    let updatedVideos = [...videos];
    // eslint-disable-next-line no-await-in-loop
    const uploadPromises = updatedVideos.map((video, index) => {
      const { title, file, duration } = video;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);
      formData.append("duration", duration);
      if (username) {
        formData.append("user", username);
      }

      return axios
        .post(`${process.env.REACT_APP_UPLOAD_BASEURL}/videos/upload/`, formData, {
          headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
          },
          timeout: 15 * 60 * 1000,
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            updatedVideos[index] = {
              ...updatedVideos[index],
              loading: true,
              file: {
                ...updatedVideos[index].file,
                uploadedPercentage: progress
              }
            };
            setVideos([...updatedVideos]); // Batch update state
          },
        })
        .then((response) => {
          if (response.status === 201 || response.status === 202) {
            videoUploaded.add(video);
            updatedVideos[index] = {
              ...updatedVideos[index],
              loading: false,
              file: { ...updatedVideos[index].file, uploadedPercentage: 100 },
            };
            setErrors((prev) => [
              ...prev,
              { show: true, severity: "success", message: response.data.message },
            ]);
          }
        })
        .catch((error) => {
          console.error("Upload error:", error);
          if (error.response?.status === 409) {
            videoUploaded.add(video);
          } else {
            toast.error("Error while uploading the video");
          }

          const errorData = error.response?.data;
          if (errorData) {
            try {
              Object.entries(errorData).forEach(([key, messages]) => {
                messages.forEach((msg) => {
                  setErrors((prev) => [
                    ...prev,
                    { show: true, severity: "error", message: `${key}: ${msg}` },
                  ]);
                });
              });
            } catch {
              console.log(errorData);
            }
          }

          updatedVideos[index] = { ...updatedVideos[index], loading: false };
          setUploading((prev) => ({ ...prev, uploaded: false }));
        });
    });

    await Promise.all(uploadPromises);

    // Filter out videos that failed to upload
    setVideos(updatedVideos.filter((video) => !videoUploaded.has(video)));
    setProcess(false);
  };


  const handleCloseAlert = (error) => (event) => {
    event.stopPropagation();
    setErrors((prev) => prev.filter((e) => e.message !== error.message));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    multiple: true,
    disabled: uploading.uploaded || inProcess
  });

  useBeforeUnload({
    when: inProcess,
    message: "Are you sure you want to cancel it.",
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      {/* Title */}
      <Typography variant="h4" paragraph>
        Upload Videos
      </Typography>
      {props.user?.is_staff &&
        <Box display="flex" gap={1} flexDirection={{ xs: "column", md: "row" }} alignContent={"center"}>
          <Typography variant="h6" >Assigned To: </Typography>
          <Box sx={{ minWidth: 300 }}>
            <UserSelector disabled={inProcess} filters={{ is_creator: true, is_promouser: false }} value={user} onChange={(e, _u) => { setUser(_u) }} />
          </Box>
        </Box>
      }
      {/* Alert on xs */}
      <Hidden mdUp>
        {errors.map((e, i) => (
          <Collapse in={e.show}>
            <Alert
              onClose={handleCloseAlert(e)}
              sx={{ mt: 1, fontSize: 12 }}
              severity={e.severity}
            >
              {e.message}
            </Alert>
          </Collapse>
        ))}
      </Hidden>
      {/* Upload Box */}
      <Box
        {...getRootProps()}
        sx={{
          width: { xs: "90%", md: "80%", xl: "50%" },
          border: "2px dashed",
          borderColor: "primary.dark",
          borderRadius: 4,
          padding: 2.5,
          // minHeight: 300,
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "grey.100",
          },
        }}
      >
        <input {...getInputProps()} />
        {user &&
          <Typography variant="body1" mt={2} paragraph>
            {`User: `}<Box component="span" sx={{ textDecoration: "underline", color: "", fontWeight: 600 }}>{user?.username}</Box>
          </Typography>
        }
        <Typography variant="body1" fontWeight="bold" mt={user ? 0 : 2} >
          Drag video files here, or{" "}
          <Typography component="span" color="primary" fontWeight="bold">
            click
          </Typography>{" "}
          to select files
        </Typography>
        <Typography variant="body1">
          Allowed{" "}
          <Tooltip title={videoFormats.map((fmt) => fmt + ", ")}>
            <Box
              component="strong"
              display="inline-flex"
              alignItems="center"
              sx={{ textDecoration: "underline", color: "primary.main" }}
            >
              formats <InfoIcon fontSize="small" />
            </Box>
          </Tooltip>
        </Typography>

        <Hidden mdDown>
          {errors.map((e, i) => (
            <Collapse in={e.show}>
              <Alert
                onClose={handleCloseAlert(e)}
                sx={{ mt: 1 }}
                severity={e.severity}
              >
                {e.message}
              </Alert>
            </Collapse>
          ))}
        </Hidden>
        <Box sx={{ mt: 2 }}>
          {videos.length > 0 && (
            <Typography variant="h6">Selected Videos:</Typography>
          )}
          <Box>
            {videos.length <= 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CloudUploadIcon
                  sx={{
                    fontSize: { md: 150, xs: 100 },
                    color: "grey",
                    mt: 2,
                  }}
                />
                <Typography variant="h5">Upload Videos</Typography>
              </Box>
            )}
          </Box>

          <List
            disablePadding
            sx={{
              zIndex: 2,
              width: "100%",
              display: videos.length > 0 ? "flex" : "none",
            }}
          >
            <Scrollbars style={{ height: 250 }}>
              {videos.map((file, index) => (
                <div key={index}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        aria-label="comment"
                        disabled={file.loading}
                        onClick={(event) => removeVideo(index, event)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      {file.loading ? (
                        <CircularSpinner
                          progress={file.file.uploadedPercentage}
                        />
                      ) : (
                        <Tooltip title={"." + file.extension}>
                          <PlayCircleIcon fontSize="large" color="success" />
                        </Tooltip>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <TextField
                          size="small"
                          variant="standard"
                          disabled={file.loading}
                          defaultValue={file.title}
                          id={`videofield-${index}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={handleVideoTitle(index)}
                        />
                      }
                      secondary={file.file.size >= 1024 * 1024 * 1024
                        ? (file.file.size / (1024 * 1024 * 1024)).toFixed(2) + " GB"
                        : file.file.size >= 1024 * 1024
                          ? (file.file.size / (1024 * 1024)).toFixed(1) + " MB"
                          : (file.file.size / 1024).toFixed(2) + " KB"}
                    />
                  </ListItem>
                  <Divider component="li" variant="inset" />
                </div>
              ))}
            </Scrollbars>
          </List>
        </Box>
        {inProcess && (
          <Box width="100%" mt={1}>
            <LinearProgress color="success" />
          </Box>
        )}
      </Box>

      {/* Start Progress Button */}
      <Button
        startIcon={<CloudUploadIcon />}
        disabled={videos.length < 1 || inProcess || uploading.uploaded}
        onClick={uploadVideos}
        variant="contained"
        color="primary"
      >
        {uploading.uploaded ? !inProcess ? "Video Uploaded Successfully" : "Uploading please wait..." : "Proceed to upload"}
      </Button>

    </Box>
  );
};

const mapStatesToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStatesToProps, null)(VideoUploader);
