import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import api from '../../conf/api';

function UserSelector({ value, onChange, filters = {}, ...other }) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {

        async function fetchUsers() {
            try {
                const response = await api.get('/users/list', { params: { is_creator: false, is_admin: false, is_promouser: true, page: 1, page_size: 10000, ...filters } });
                const results = response.data?.results;
                setUsers(results);
                setLoading(false);
                if (!(value instanceof Object)) {
                    const _value = results.filter(r => r.username === value)[0];
                    setUser(_value);
                }

            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }

        fetchUsers();
    }, []);

    const _onChange = (e, new_value) => {
        onChange(e, new_value);
        setUser(new_value);
    }

    return (
        <Autocomplete
            size='small'
            options={users}
            value={user}
            getOptionLabel={(user) => user.username}
            loading={loading}
            onChange={_onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? 'Loading...' : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            {...other}
        />
    );
}

export default UserSelector;
