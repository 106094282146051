import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText, CircularProgress, Box, FormGroup, Checkbox, FormControlLabel, Divider, TextField } from "@mui/material";
import api from "../../../conf/api";
import { toast } from "react-toastify";
import Scrollbars from "react-custom-scrollbars-2";

const VideoStatusDialogBox = ({ open, onClose, videos, status = "Approval", refrashFunc = () => { } }) => {
    const [loading, setLoading] = useState(false)
    const [paths, setPaths] = useState([]);
    const [selectedPaths, selectPaths] = useState([]);
    const [note, setNote] = useState("");


    const AddorRemoveExtraPaths = (add, _path) => {
        selectPaths(pre => {
            if (!add)
                return pre.filter(p => p !== _path)
            else {
                return [...pre, _path]
            }
        })
    }

    const onConfirm = async () => {
        setLoading(true);
        api.post(`/videos/bulk/approve/${status}/`, {
            videos: videos,
            note: note,
            paths: selectedPaths
        }).then((res) => {
            if (res.status === 200) {
                toast.success(res.data);
                refrashFunc();
                onClose();

            } else {
            }
        }).catch((err) => {
            toast.error(err.response?.data);
        }).finally(res => {
            setLoading(false);
        });
    }

    useEffect(() => {
        const fetchPaths = async () => {
            try {
                const response = await api.get("/auth/dropbox/paths");
                setPaths(response.data?.folders);
            } catch (error) {
                console.error("Error fetching paths", error);
            }
        };
        if (status === "Approval") {
            fetchPaths();
        }
    }, [])
    return (
        <Dialog open={open} onClose={!loading && onClose} maxWidth="sm" fullWidth>
            <DialogTitle>⚠️ Warning: Confirm {status}</DialogTitle>

            <DialogContent>

                <Typography variant="body1">
                    Are you sure you want to {status === "Approval" ? "approve" : "reject"} the following videos?
                </Typography>
                <Scrollbars style={{ height: 165 }}>
                    <List disablePadding subheader="Selected videos:" >
                        {videos.map((video) => (
                            <ListItem key={video.id} divider>
                                <ListItemText
                                    primary={video.title}
                                    secondary={`ID: ${video.id}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Scrollbars>
                {status === "Approval" &&
                    <Scrollbars style={{ height: 165 }}>
                        <Box display="flex" flexDirection="column">
                            <Typography sx={{ color: "warning.main" }} variant="body1" paragraph mt={2}>Alos <b>Copy</b> files to</Typography>
                            <FormGroup sx={{
                                "& .MuiTypography-root": {
                                    color: "primary.main"
                                }
                            }}>
                                {paths.map(path => (
                                    <FormControlLabel
                                        checked={selectedPaths.includes(path)}
                                        control={<Checkbox size="small" />}
                                        label={path}
                                        onChange={(_e, value) => { AddorRemoveExtraPaths(value, path) }}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    </Scrollbars>
                }
                {status === "Rejection" &&
                    <TextField
                        label="Disabling Note"
                        value={note}
                        onChange={e => { setNote(e.target.value) }}
                        multiline
                        fullWidth
                        color="warning"
                        size="large"
                        minRows={4}
                        InputProps={{ sx: { textAlign: "top" } }}
                        sx={{ mt: 2 }}
                    />

                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onClose} color="error" variant="outlined">
                    Cancel
                </Button>
                {loading ? <CircularProgress size={35} /> :
                    <Button onClick={onConfirm} color={status === "Approval" ? "success" : "error"} variant="contained">
                        Confirm {status}
                    </Button>
                }
            </DialogActions>
        </Dialog >
    );
};

export default VideoStatusDialogBox;
