import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import api, { baseURL } from "../../conf/api.js";
import Scrollbars from "react-custom-scrollbars-2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import PromoVideoBox from "./components/PromoVideoBox.js";
import ConfirmDeleteVideoBox from "./components/ConfirmDeleteVideoBox.js";
import DateInput from "../../Components/Fields/DatePicker.js";
import { isValidDateRange } from "../../utils.js";
import { toast } from "react-toastify";
import UserAutoComplete from "../../Components/AutoCompletes/UserAutoComplete.js"
import { useSearchParams } from "react-router-dom";
import AddCompilationAssetsBox from "./components/AddCompilationAssetsBox.js"
import { useParams } from 'react-router-dom';
import CircleIcon from "@mui/icons-material/Circle";

function CompilationVideos() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters = {
    date_from: searchParams.get("date_from") || null,
    date_to: searchParams.get("date_to") || null,
    user: searchParams.get("user") || null
  };
  const [isDropBoxConnected, setDropboxStatus] = useState(undefined);
  const [filters, setFilters] = useState(initialFilters);
  const [params, setParams] = useState(null);
  const [data, setData] = useState([]);
  const [assetBox, setAssetBox] = useState(false);
  const [addAssetBox, setAddAssetBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [is_update, setIsUpdate] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 3,
    page: 0,
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 90,
    },
    {
      field: "video_key",
      headerName: "WGID",
      minWidth: 180,
    },
    {
      field: "video_title",
      headerName: "Video Title",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duration",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "dropbox_link",
      headerName: "Fetch (DropBox URL)",
      type: "url",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Edit Asset">
            <IconButton onClick={handleUpdateAsset(params.row?.id)}>
              <CreateIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Asset">
            <IconButton onClick={handleDeletetAsset(params.row)}>
              <DeleteIcon sx={{ color: "error.dark" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleFilterChange = (name, value) => {
    if (value !== null) {
      setFilters((pre) => ({
        ...pre, [name]: value,
      }));
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set(name, value);
      setSearchParams(queryParams);
    }
  }

  const handleFilterClear = () => {
    const queryParams = new URLSearchParams(searchParams);
    const _filters = { ...filters };
    for (let key in _filters) {
      _filters[key] = "";
      queryParams.delete(key);
    }
    setFilters(_filters);
    setParams((pre) => ({
      ...pre,
      ..._filters
    }));
    setSearchParams(queryParams);
  }

  const handleFilterApply = (event) => {
    event.preventDefault();
    if (!isValidDateRange(filters.date_from, filters.date_to)) {
      toast.error("Invalid Date Range.");
      return;
    }
    setParams((pre) => ({
      ...pre,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      ...filters
    }));
  }

  async function fetchData(
    page = paginationModel.page,
    pageSize = paginationModel.pageSize
  ) {
    setLoading(true);
    await api
      .get("/promo/compilation_videos", {
        params: {
          ...params,
          video: id,
          page: page + 1,
          page_size: pageSize
        },
      })
      .then((res) => {
        setData(res.data?.results);
        setRowCountState(res.data?.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchDetail(_id) {
    await api
      .get(`/promo/compilation_videos/${_id}/`, { params: { video: id, } })
      .then((res) => {
        if (res.status === 200) {
          setSelectedRow(res.data);
          setAssetBox(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  },
    // eslint-disable-next-line
    [paginationModel.page, paginationModel.pageSize, params]
  );

  useEffect(() => {
    const CheckDropBoxSatatus = async () => {
      try {
        const res = await api.get("/auth/dropbox/authenticated");

        if (res.status === 200) {
          setDropboxStatus(true);
        }
      } catch (err) {
        setDropboxStatus(false);
        // GetDropboxURL();
      }
    };

    // Run the function initially
    CheckDropBoxSatatus();

    // Set up an interval to run the function every 2 minutes (120000 milliseconds)
    const intervalId = setInterval(CheckDropBoxSatatus, 120000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const handleUpdateAsset = (_id) => async () => {
    if (isDropBoxConnected) {
      setIsUpdate(true);
      fetchDetail(_id);
    }
    else {
      toast.error("Connect to Dropbox first.")
    }
  };

  const handleDeletetAsset = (row) => async () => {
    setSelectedRow(row);
    setConfirmBox(true);
  };

  const CompilationAssetBox = useCallback(
    () => (
      <PromoVideoBox
        open={assetBox}
        selectedRow={selectedRow}
        is_update={is_update}
        onClose={() => {
          setIsUpdate(false);
          setSelectedRow({});
          setLoading(false);
          setAssetBox(false);
        }}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [assetBox, is_update, selectedRow]
  );

  const AddCompilationAssetBox = useCallback(
    () => (
      <AddCompilationAssetsBox
        open={addAssetBox}
        refreshFunc={fetchData}
        selectedCompilation={data[0]?.compilation_obj}
        onClose={() => {
          setLoading(false);
          setAddAssetBox(false);
        }}
      />
    ),
    // eslint-disable-next-line
    [addAssetBox]
  );

  const DeleteAssetDialog = useCallback(
    () => (
      <ConfirmDeleteVideoBox
        selectedRow={selectedRow}
        open={confirmBox}
        setOpen={setConfirmBox}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [confirmBox, selectedRow]
  );

  return (
    <Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ color: "grey.600" }} fontWeight={"bold"}>
          Compilation Assets
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleFilterApply}>
          <Grid container spacing={2} alignItems="center" padding={2}>
            <Grid item xs={12} lg={4}>
              <UserAutoComplete
                value={filters.user}
                user={filters.user}
                onChange={(user) => handleFilterChange("user", user)}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DateInput
                label="Date From"
                value={filters.date_from}
                onChange={(date) => handleFilterChange('date_from', date)}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DateInput
                label="Date To"
                value={filters.date_to}
                onChange={(date) => handleFilterChange('date_to', date)}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="right"
            padding="0 30px"
          >
            <Button onClick={handleFilterClear} sx={{ marginRight: "10px" }}>Clear</Button>
            <Button variant="contained" type="submit" color="success">Apply Filters</Button>
          </Box>
        </form>
      </Box>
      <Box
        display={isDropBoxConnected === undefined ? "none" : "flex"}
        alignItems="center"
        component={!isDropBoxConnected && "a"}
        href={`${baseURL}/auth/dropbox/access_token/`}
        target="_blank"
      >
        <Tooltip
          title={!isDropBoxConnected && "Click to authenticate dropbox"}
        >
          <IconButton size="large">
            <CircleIcon
              fontSize="large"
              color={isDropBoxConnected ? "success" : "error"}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={!isDropBoxConnected && "Click to authenticate dropbox"}
        >
          <Typography variant="h5">
            Storage is {isDropBoxConnected ? "connected" : "disconnected"}
          </Typography>
        </Tooltip>
      </Box>
      <Box mt={4} component={Paper} borderRadius={4}>
        <Box
          border="1px solid"
          borderColor="grey.400"
          borderRadius={"10px 10px 0px 0px"}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="grey" fontWeight={600}>
            Compilation Videos
          </Typography>
          <Tooltip title="Add New Channel">
            <IconButton
              size="small"
              onClick={() => {
                isDropBoxConnected ?
                  setAddAssetBox(true) :
                  toast.error("Connect to Dropbox first.")
              }}
            >
              <AddCircleIcon color="success" />
            </IconButton>
          </Tooltip>
        </Box>
        <Scrollbars style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            loading={loading}
            paginationMode="server"
            rowCount={rowCountState}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowSelection={false}
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
          />
        </Scrollbars>
      </Box>
      {/* Add new channel */}
      <CompilationAssetBox />
      {/* Add New Asset */}
      <AddCompilationAssetBox />
      {/* Confirm Box  */}
      <DeleteAssetDialog />
    </Box>
  );
}

export default CompilationVideos;
